.link {
    text-decoration: underline;
}

.AccTabSec .nav-item {
    max-width: 340px !important;
}

.AccTabSec .img-thumbnail {
    max-width: 230px !important;
}

.invoiceBox {
    display: block;
    margin: auto;
    overflow: auto;
    background-color: #ffffff;
    max-width: fit-content;
}

.invoiceBox table {
    border: none;
}

.Invoice_Panel_Box {
    cursor: pointer;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #333333;
    font-size: 28px;
    font-weight: 700;
    color: #ffffff;
    position: fixed;
    right: 0;
    top: 0;
}

.modal-fullscreen {
    height: 100%;
}
.modal-fullscreen .modal-content {
    background-color: #efefef;
}

.is-invalid {
    border-color: red;
}

.refund__listing {
    /* flex flex-col gap-3 */
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.refund__listing .card {
    border: 1px solid #d2d1d1;
}

.refund__listing .card .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5;
    padding: 1rem 1.5rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.refund__listing .card .top .metadata {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.refund__listing .card .top .metadata .credit-note {
    /* text-xs sm:text-sm md:w-[300px] */
    font-size: 16px;
    width: 300px;
    margin: 0;
}

.refund__listing .card .top .metadata .credit-note span {
    font-weight: 600;
}

.refund__listing .card .top .metadata .rma-id {
    /* text-xs font-semibold text-primary sm:text-sm */
    font-size: 16px;
    font-weight: 600;
    color: #cc3500;
    margin: 0;
}

.refund__listing .card .top .metadata .status {
    /* text-xs font-semibold uppercase sm:text-sm */
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
}

.refund__listing .card .top .metadata .status.completed {
    color: #008000;
}

.refund__listing .card .top .metadata .status.rejected {
    color: #c3001f;
}

.refund__listing .card .top .metadata .status.pending {
    color: #0094e8;
}

.refund__listing .card .top .metadata .order-id {
    /* text-xs font-semibold sm:text-sm */
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}

.refund__listing .card .bottom {
    padding: 1rem 1.5rem;
}

.refund__listing .card .bottom .container {
    /* flex flex-col justify-between sm:flex-row */
    display: flex;
    justify-content: space-between;
}

.refund__listing .card .bottom .container .refund-items {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.refund__listing .card .bottom .container .refund-items .refund-item {
    display: flex;
    align-items: start;
    gap: 2rem;
}

.refund__listing .card .bottom .container .refund-items .refund-item .refund-image {
    height: auto;
    width: 200px;
    object-fit: cover;
}

.refund__listing .card .bottom .container .refund-items .refund-item .item-name {
    /* text-xs font-semibold sm:text-sm */
    font-size: 16px;
    font-weight: 700;
    margin-top: 1.5rem;
}

.refund__listing .card .bottom .container .cta {
    margin-top: auto;
    padding: 1rem 0;
}

.refund__listing .card .bottom .container .cta .view-details {
    /* rounded-[4px] bg-primary py-2.5 px-10 text-xs font-semibold text-white sm:text-sm */
    border-radius: 4px;
    background-color: #cc3500;
    color: white;
    padding: 0.625rem 2.5rem;
    font-size: 16px;
    font-weight: 600;
}

.refund-filter-container {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.refund-filter-container > * + * {
    margin-left: 0.5rem;
}

.refund-filter-container > select {
    width: 200px;
}

.refund-filter-container > input {
    width: 400px;
}

.refund-filter-container .cta {
    background-color: transparent;
    border: none;
}

.refund-filter-container .cta .search-icon {
    height: 1.5rem;
    width: 1.5rem;
    color: #808080;
}
