.page-link {
  color: grey !important;
}
.active > .page-link {
  background-color: #cc3500 !important;
  color: #ffffff !important;
}
ul.pagination li.page-item .page-link { border-radius: 0px;}
ul.pagination li.page-item.active { --bs-pagination-color:none; --bs-pagination-active-border-color:#dee2e6}









