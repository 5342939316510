.App-header {
  position: relative;
  width: 100%;
  z-index: 1000;
}

.sticky-top {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
}

/* Cart*/
.cart_car {
  position: relative;
  display: inline-block;
}

.cart_car img {
  display: block;
  max-width: 100%;
}

.cart_box {
  position: absolute;
  top: 0;
  right: 0;
  width: 35px;
  height: 20px;
  border-radius: 30px;
  background-color: red; /* Set the background color */
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(10px);
}

.cart_box span {
  font-size: 10px;
  font-weight: bold;
  color: white;
}
