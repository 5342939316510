/* checkout */
.border-grey{
    border: 1px solid #c7c7c7;
}

.shipping_info_box{
    position: relative;
    border: 1px solid #c7c7c7;
 
}

.shipping_box{
    position: relative;
}


.edit_wrap{
    position: absolute;
    top: 0;
    right: 0;

}

.shipping_info_truck{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #ffffff;

}

.address_list{
    position: relative;
}
