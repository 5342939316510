/* switch checkbox */
/* The switch - the box around the slider */

.language_box {
  max-width: 310px;
}

.lang {
  font-size: 16px;
  padding: 5px 5px;
  margin: 0;
  line-height: 35px;
}
/* container */

.switch {
  position: relative;
  display: inline-block;
  width: 75px;
  height: 35px;
  text-align: center;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f0f0f0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.flag {
  position: absolute;
  content: "";
  height: 32px;
  width: 32px;
  left: 8%;
  bottom: 1px;
  background-color: white;
  border-radius: 30px;
  background-size: cover;

  background-image: url("../../../../public/assets/ico_thai.png");
  -webkit-transition: 0.4s;
  transition: 0.4s;
  z-index: 10;
  cursor: pointer;
}

input:checked + .slider {
  background-color: blue;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ffffff;
}

input:checked + .flag {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  background-image: url("../../../../public/assets/ico_eng.png");
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media (max-width: 1200px) {
  .flag {
    height: 30px;
    width: 30px;
    left: 10%;
  }
  .switch {
    width: 88px;
    height: 33px;
  }
}

@media (max-width: 991.98px) {
  .switch {
    width: 80px;
    height: 37px;
  }
  .flag {
    height: 35px;
    width: 35px;
    left: 5%;
  }
  .language_box {
    max-width: 100%;
  }
  input:checked + .flag {
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
  }
}
