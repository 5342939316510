.cookie-bar-wrap {
    background: rgba(0,0,0,.55);
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    color: #fff;
    transform: translateY(100%);
    transition: all .3s ease-in;
}

.cookie-bar-wrap.show {
    transform: translateY(0);
    transition: all .3s ease-in;
    opacity: 1;
}

.btn-primary {
    color: #000000;
    background-color: #FFC20E;
    font-weight: bold;
    text-transform: uppercase!important;
    border: 0;
    border-radius: 0;
    min-width: 100px;
    margin: 10px;
}

.btn-primary:hover {
    color: #FFC20E;
    background-color: #000000;
}

.btn-secondary {
    color: #fff;
    background-color: #DE0A17;
    font-weight: bold;
    text-transform: uppercase!important;
    border: 0;
    border-radius: 0;
    min-width: 100px;
    margin: 10px;
}

.btn-secondary:hover {
    color: #fff;
    background-color: #000000;
}

.cookiePolicyAnchor{
    color: #FFC20E;
    text-decoration: underline;
}

.cookiePolicyAnchor:hover{
    color: black;
    text-decoration: underline;
}
