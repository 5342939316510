.categories_panel{
    background-color: #ffc423;
    width: 100%;
    max-width: 400px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: scroll;
    z-index: 2000; transform: translateX(-100%); transition: all .2s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.categories_panel.expanded {transform: translateX(0%);}
.categories_panel.expanded ~ .menu-backdrop { background-color: #000; opacity: .5; width: 100%; height: 100%; position: fixed; top: 0; left: 0; z-index: 1111;}

.categories_panel::-webkit-scrollbar {
    display: none;
  }


  .categories_panel .accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
    transform: scale(.7) !important;
  }
  .categories_panel .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
  }





  .link_underline:hover {
    text-decoration: underline;
  }

  .categories_panel .accordion-button:not(.collapsed) {
    color: #333333;
    box-shadow: none;
    border-radius: 0;
    background-color: transparent;
    cursor: default;
}



.categories_panel .accordion-button:focus {
    border-color: transparent;
    box-shadow: none;
    background-color: transparent;
}

.main_accordionitem .menu-container .custom_accordion_body {
  /* padding: 1.2rem 2rem; */ padding:0px;
  line-height: 0;
  border-bottom: 1px solid #dedede; background-color: transparent; --bs-accordion-bg: transparent;
}

.main_accordionitem .menu-container .custom_accordion_body a {padding: 0.9rem 2rem; display: block; background-color: #fff; color: #cc3500; line-height: normal; font-weight:600; font-size: 18px;}
.main_accordionitem .menu-container .custom_accordion_body a:hover {background-color: #cc3500; color: #fff; font-weight:600; } 

/* .main_accordionitem {
  background: #f5f5f5;
} */

.accordion {
  border: 0;
  --bs-accordion-border-width : 0;
  --bs-accordion-border-radius: 0;
 
  
}


.accordion-header{
  border-radius: 0;
}


.accordion-body{
  padding: 0;
 
  background-color: transparent;
}

.accordion-item:last-of-type .accordion-button.collapsed{
  border-radius: 0;
  cursor: default;
}



.social-icons-box ul { margin: 0 auto; padding: 0; list-style: none; text-align: center; display: block;}
.social-icons-box ul li { display: inline-block;}
.social-icons-box ul li a { padding: 7px;}