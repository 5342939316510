.form_login {
    max-width: 1000px;
    background-color: #ffffff;
    min-height: 500px;
    margin: auto;
    position: relative;
    display: flex;
    padding: 3rem 0;
}

/* tab */
.Signup-sec{
    position: relative;
    width: 100%; background-color: #eeeeee;
}

ul.signup_tabs {border-bottom: none!important;}
ul.signup_tabs li.nav-item {padding: 0;}
ul.signup_tabs li.nav-item .nav-link {border-bottom-color: #dee2e6!important; border-width: 1px!important; border-radius: 0; font-size: 18px!important; padding: 15px 0px}
ul.signup_tabs li.nav-item .nav-link.active {border-bottom-color: #ffffff!important; }
.Signup-sec .tab-content {border:1px solid #dee2e6; border-top: none; padding: 1rem; padding-top: 2rem; background: #fff;}




