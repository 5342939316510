.banner-skeleton {
  width: 100%;
  height: 300px;
}

.pulse {
  width: 100%;
  height: 100%;
  background: rgb(229 231 235);
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.3;
  }
}
