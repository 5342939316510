.page-title {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 700;
    padding-left: 0.25rem;
    margin-top: 3rem;
}

.card {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    border: none;
}

.card-container {
    border: 1px solid #d2d1d1;
}

.card .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5;
    padding: 0.75rem 1rem;
}

.card .top p.credit-note {
    width: 150px;
    font-size: 12px;
    margin: 0;
}

.card .top p.credit-note span {
    font-weight: 600;
}

.card .top .order-status {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.card .top .order-status .description {
    font-size: 12px;
    font-weight: 600;
    color: #808080;
    margin: 0;
}

.card .top .order-status .name {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
}

.completed-status {
    color: #008000;
}

.pending-status {
    color: #cc3500;
}

.processing-status {
    color: #0094e8;
}

.card .bottom {
    padding: 0.5rem;
}

.order-items {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.order-items > * + * {
    border-top: 1px solid #d2d1d1;
}

.order-item {
    display: flex;
    padding-top: 0.25rem;
}

.order-item .item-image {
    height: 150px;
    width: 150px;
    object-fit: cover;
}

.order-item .item-details {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem 1rem 1rem 0;
}

.order-item .item-name {
    font-size: 12px;
    font-weight: 600;
}

.order-item .item-quantity {
    font-size: 12px;
    font-weight: 600;
}

.order-item .item-total-price {
    padding: 1rem 0.75rem;
    font-size: 16px;
    font-weight: 600;
    color: #808080;
}

.order-details {
    display: flex;
    justify-content: flex-end;
    gap: 3rem;
    border-bottom: 1px solid #d2d1d1;
    padding: 1.5rem 1.125rem;
    color: #808080;
}

.delivery-address {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin-top: 0.25rem;
}

.delivery-address h4 {
    font-weight: 600;
    font-size: 14px;
}

.delivery-address p {
    max-width: 260px;
    font-size: 12px;
}

.cost-details {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-weight: 600;
}

.total-sum,
.delivery-charges {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}

.total-sum h4,
.delivery-charges h4 {
    width: 130px;
    text-align: left;
    font-size: 14px;
}

.total-sum p,
.delivery-charges p {
    text-align: right;
    font-size: 1.125rem;
    line-height: 1.75rem;
}

.summary {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
    gap: 1.75rem;
    padding: 1.5rem 1.125rem;
}

.total-payment {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    font-weight: 600;
    color: #808080;
}

.total-payment h4 {
    width: 130px;
    text-align: left;
    font-size: 14px;
}

.total-payment p {
    text-align: right;
    color: black;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
}

.button-container {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.button-container .refund-button,
.button-container .download-invoice-button {
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 0.5rem;
    border-radius: 4px;
    color: white;
    padding: 0.625rem 1rem;
    border: none;
}

.button-container .refund-button {
    background-color: #c3001f;
}

.button-container .download-invoice-button {
    background-color: #008000;
}

.button-container .refund-button span,
.button-container .download-invoice-button span {
    font-size: 14px;
    font-weight: 600;
}

.button-container .download-invoice-button svg {
    height: 1.125rem;
    width: 1.125rem;
}

@media (min-width: 768px) {
    .page-title {
        font-size: 1.5rem;
        line-height: 2rem;
    }

    .card .top {
        padding: 1rem 1.5rem;
    }

    .card .top p.credit-note {
        width: 300px;
        font-size: 14px;
    }

    .card .top .order-status {
        gap: 2rem;
    }

    .card .top .order-status .description {
        font-size: 14px;
    }

    .card .top .order-status .name {
        font-size: 14px;
    }

    .card .bottom {
        padding: 1rem 1.5rem;
    }

    .order-items {
        gap: 0.75rem;
    }

    .order-item {
        padding-top: 0.75rem;
    }

    .order-item .item-image {
        height: auto;
    }

    .order-item .item-details {
        padding: 2rem 6rem;
    }

    .order-item .item-name {
        font-size: 14px;
    }

    .order-item .item-quantity {
        font-size: 14px;
    }

    .order-item .item-total-price {
        padding: 2rem 1.5rem;
        font-size: 18px;
    }

    .order-details {
        padding: 1.5rem 3rem;
    }

    .delivery-address {
        flex-direction: row;
    }

    .summary {
        padding: 1.5rem 3rem;
    }

    .total-payment p {
        font-size: 1.125rem;
        line-height: 1.75rem;
    }
}
