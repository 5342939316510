.ico_rounded {
  background-color: #e80012;
  width: 130px;
  height: 130px;
  display: table;
  margin: auto;
  border-radius: 65px;
  position: relative;
}

.ico_rounded:hover {
  background-color: #cc141c;
}

.ico_rounded img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ico_sec {
  max-width: 210px;
}

@media (min-width: 640px) {
  .ico_sec {
    width: 210px;
    max-width: 210px;
  }
}

@media (max-width: 1200px) {
  .ico_rounded {
    width: 90px;
    height: 90px;
  }
  .ico_rounded img {
    max-height: 90px;
  }
}

@media (max-width: 576px) {
  .ico_rounded {
    width: 55px;
    height: 55px;
  }
  .ico_rounded img {
    max-height: 55px;
  }
}
