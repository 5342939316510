.carousel-indicators [data-bs-target] {
  width: 20px !important;
  height: 20px !important;
  border-radius: 100%;
}

.carousel-indicators .active {
  background-color: #e80012;
}

.carousel-indicators {
  margin-bottom: 0.5rem;
}

.banner__image {
  width: 100%;
  height: auto;
}
