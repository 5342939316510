/* Subscribe */
.box_search_subscribe {
  position: absolute;
  right: 0;
  top: 0;
  width: 29%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: 700;
}

.is-invalid {
  border-color: red;
}
