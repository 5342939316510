/* InfoSec */

.modal-body {
    position: relative;
}

.modal-content {
    background: #fff;
    border: none;
    box-shadow: none;
}

.modal-video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    width: 100%;
    height: 0;
    overflow: hidden;
}

.modal-video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.modal-container {
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    border: 1px solid red;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    padding: 5px 10px;
    z-index: 1;
}

.close-button:focus {
    outline: none;
}

.close-icon {
    font-size: 20px;
    font-weight: bold;
}
