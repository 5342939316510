/* data_group */
.data_wrap {
  padding: 2rem;
}

.data_list {
  border: 1px solid #c9c9c9;
  position: relative;
}

.discount_label {
  background-color: #333333;
  color: #ffffff;
  position: absolute;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
}
.new_label {
  background-color: #00a0e6;
  color: #ffffff;
  position: absolute;
  width: 55px;
  height: 55px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  transform: translateX(-54px);
}
.new_label_end {
  background-color: #00a0e6;
  color: #ffffff;
  position: absolute;
  width: 55px;
  height: 55px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
}

.row.display-flex {
  display: flex;
  flex-wrap: wrap;
}

.row.display-flex > [class*="col-"] {
  display: flex;
  flex-direction: column;
}

.img-data {
  width: 247px;
  height: 247px;
  object-fit: contain;
}

.LazyLoad {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.is-visible {
  opacity: 1;
}

.prod-item-col {
  margin: 0;
}
.prod-item-col .data_list {
  height: 100%;
  transition: all 0.5s ease;
  background-color: #fff;
}
.prod-item-col .data_list:hover {
  box-shadow: rgba(45, 45, 45, 0.05) 0px 2px 2px,
    rgba(49, 49, 49, 0.05) 0px 4px 4px, rgba(42, 42, 42, 0.05) 0px 8px 8px,
    rgba(32, 32, 32, 0.05) 0px 16px 16px, rgba(49, 49, 49, 0.05) 0px 32px 32px,
    rgba(35, 35, 35, 0.05) 0px 64px 64px;
  transform: translate(0, -4px);
}
.prod-item-col .data_list .data_box .img-box .img-data {
  margin: 0 auto;
  display: block;
}
.prod-item-col .product-item-info {
  margin: 0;
}
.prod-item-col .product-item-info .prod-item-name {
  display: block;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 0.6rem;
  font-weight: 500;
}

@media (max-width: 992px) {
  .data_wrap {
    padding: 1.5rem 1rem;
  }

  .discount_label {
    position: absolute;
    width: 40px;
    height: 40px;
    font-size: 10px;
  }
  .new_label {
    width: 40px;
    height: 40px;
    font-size: 10px;
    transform: translateX(-40px);
  }
  .new_label_end {
    width: 40px;
    height: 40px;
    font-size: 10px;
  }

  .prod-item-col .data_list .data_box .img-box {
    height: auto !important;
  }
}

@media (max-width: 768px) {
  .data_wrap {
    padding: 1.5rem 0.5rem;
  }
  .prod-item-col .data_list .data_box .img-box .img-data {
    width: 40vw;
    height: 40vw;
  }
}

.cus_form_select {
  outline: 0 !important;

  border: none !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #eeeeee !important;
}

.cus_form_select:focus-visible,
.cus_form_select:active,
.cus_form_select:focus {
  outline: 0 !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #eeeeee !important;
}

.outline-0 {
  outline: 0 !important;
}

.border-0 {
  border: 0 !important;
}

.outline-0:hover,
.border-0:hover,
.outline-0:active,
.border-0:active,
.outline-0:focus,
.border-0:focus {
  outline: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
}
