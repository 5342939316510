.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page {
    max-width: 1000px;
    margin: 0 auto;
}

.page-title {
    font-weight: 700;
    font-size: 30px;
    line-height: 41px;
    padding: 0;
}

.order-id {
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #c3001f;
    margin-bottom: 0.5rem;
}

.order-id,
.order-id:hover {
    text-decoration: underline;
    color: #c3001f;
}

.refund-id {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
}

.refund-description {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 2rem;
}

.refund-description label {
    display: block;
    font-size: 12px;
    font-weight: 600;
    color: rgb(17 24 39);
}

.refund-description textarea {
    width: 100%;
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
    font-size: 12px;
    border: 1px solid #ffc107;
    outline: none;
}

.defect-images {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
}

.defect-images p {
    display: block;
    font-size: 12px;
    font-weight: 600;
    color: rgb(17 24 39);
}

.refund-status {
    width: fit-content;
    padding: 0.25rem 1rem;
    border-radius: 4px;
}

.refund-status.completed {
    background: #008000;
}

.refund-status.rejected {
    background: #c3001f;
}

.refund-status.pending {
    background: #0094e8;
}

.refund-status span {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
}

.refund-item {
    display: flex;
    align-items: center;
    padding-top: 1.5rem;
}

.refund-item .item-image {
    border-radius: 4px;
    object-fit: cover;
    object-position: center;
    height: auto;
    width: 215px;
}

.refund-item .item-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.refund-item .item-details .item-form {
    display: flex;
    gap: 1rem;
}

.refund-item .item-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    flex: 1;
    /* padding: 0 4rem; */
}

.refund-item .item-condition {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 0.5rem;
    /* margin: 0 1rem; */
}

.refund-item .item-condition label {
    display: block;
    font-size: 12px;
    font-weight: 600;
    color: rgb(17 24 39);
}

.refund-item .item-condition .select-container {
    display: flex;
    border-radius: 4px;
}

.refund-item .item-condition .select-container select {
    display: block;
    width: 100%;
    padding: 0.5rem;
    font-size: 12px;
    color: rgb(17 24 39);
    background: #ffffff;
    border: 1px solid #ffc107;
    border-radius: 4px;
}

.return-quantity {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.return-quantity label {
    display: block;
    font-size: 12px;
    font-weight: 600;
    color: rgb(17 24 39);
}

.return-quantity .input-container {
    display: flex;
    border-radius: 4px;
}

.return-quantity .input-container input {
    width: 4rem;
    border-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 0.5rem;
    text-align: right;
    font-size: 12px;
    background: #ffffff;
    border: 1px solid #ffc107;
    border-radius: 4px;
    outline: none;
}

.return-quantity .input-container span {
    display: inline-flex;
    align-items: center;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid rgb(209 213 219);
    border-left: none;
    padding: 0.5rem;
    font-size: 12px;
    color: rgb(107 114 128);
}

.refund-reason {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
    max-width: 500px;
}

.refund-reason label {
    display: block;
    font-size: 12px;
    font-weight: 600;
    color: rgb(17 24 39);
}

.refund-reason .select-container {
    display: flex;
    border-radius: 4px;
}

.refund-reason .select-container select {
    display: block;
    width: 100%;
    padding: 0.5rem;
    font-size: 12px;
    color: rgb(17 24 39);
    background: #ffffff;
    border: 1px solid #ffc107;
    border-radius: 4px;
}

.refund-resolution {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
    max-width: 500px;
}

.refund-resolution label {
    display: block;
    font-size: 12px;
    font-weight: 600;
    color: rgb(17 24 39);
}

.refund-resolution .select-container {
    display: flex;
    border-radius: 4px;
}

.refund-resolution .select-container select {
    display: block;
    width: 100%;
    padding: 0.5rem;
    font-size: 12px;
    color: rgb(17 24 39);
    background: #ffffff;
    border: 1px solid #ffc107;
    border-radius: 4px;
    outline: none;
}

.bank-details-title {
    font-weight: 700;
    font-size: 30px;
    line-height: 41px;
    color: #000000;
    margin-top: 2rem;
}

.bank-details {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

.bank-account-no,
.bank-name,
.account-name {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
}

.bank-account-no label,
.bank-name label,
.account-name label {
    display: block;
    font-size: 12px;
    font-weight: 600;
    color: rgb(17 24 39);
}

.bank-account-no .input-container,
.bank-name .input-container,
.account-name .input-container {
    display: flex;
    border-radius: 4px;
}

.bank-account-no .input-container input,
.bank-name .input-container input,
.account-name .input-container input {
    display: block;
    width: 100%;
    padding: 0.5rem;
    font-size: 12px;
    color: rgb(17 24 39);
    background: #ffffff;
    border: 1px solid #ffc107;
    border-radius: 4px;
    outline: none;
}

.messages {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1.5rem;
}

.messages p {
    display: block;
    font-size: 12px;
    font-weight: 600;
    color: rgb(17 24 39);
    margin: 0;
}

.messages-container {
    display: flex;
    height: 347px;
    flex-direction: column;
    gap: 0.75rem;
    overflow-y: scroll;
    border-radius: 4px;
    background-color: #f5f5f5;
    padding: 1.25rem 1rem;
}

.message-card {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.message-card.admin {
    width: 398px;
}

.message-card.customer {
    margin-left: auto;
    width: 358px;
}

.message-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.message-type {
    font-size: 14px;
    font-weight: 300;
    color: #808080;
}

.message-time {
    font-size: 14px;
    font-weight: 300;
    color: #808080;
}

.message {
    border-radius: 4px;
    padding: 0.75rem 1rem;
    font-size: 14px;
    font-weight: 300;
    color: #808080;
}

.message p {
    margin: 0;
    font-size: 14px;
}

.message.customer {
    background: #e5e5e5;
}

.message.admin {
    background: white;
}

.screenshots {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.75rem;
    padding: 0;
}

.screenshot {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 6rem;
    width: 6rem;
    overflow: hidden;
    border-radius: 6px;
    border: 1px solid #e5e7eb;
    padding: 0.5rem;
}

.add-screenshot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #d2d1d1;
    border: 1px dashed #d2d1d1;
    height: 6rem;
    width: 6rem;
    border-radius: 6px;
    background: #fff;
    cursor: pointer;
}

.add-screenshot svg {
    width: 2rem;
    height: 2rem;
}

.add-screenshot span {
    font-weight: 500;
    font-size: 12px;
}

.add-screenshot input {
    display: none;
}

.screenshot img {
    height: 100%;
    width: 100%;
    cursor: zoom-in;
    border-radius: 6px;
    object-fit: cover;
    object-position: center;
}

.attachments {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1.5rem;
}

.attachments p {
    display: block;
    font-size: 12px;
    font-weight: 600;
    color: rgb(17 24 39);
    margin: 0;
}

.attachments .attachment {
    display: flex;
    align-items: center;
    gap: 3rem;
    border-bottom: 1px solid #ddd;
    padding: 0.5rem 0;
    width: fit-content;
}

.attachments .attachment p {
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
    color: #808080;
}

.attachments .attachment .download-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 12px;
    font-weight: 400;
    color: #808080;
    background: none;
    border: none;
}

.attachments .attachment .download-button:hover {
    text-decoration: underline;
}

.attachments .attachment .download-button svg {
    width: 1rem;
    height: 1rem;
}

.page .cta {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
}

.submit-button {
    background: #c3001f;
    width: fit-content;
    padding: 0.5rem 2rem;
    border: none;
    border-radius: 4px;
}

.submit-button span {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
}

.new-message {
    margin-top: 0.25rem;
    display: flex;
    border-radius: 4px;
}

.new-message .input-container {
    position: relative;
    display: flex;
    flex: 1;
}

.new-message .input-container input {
    display: block;
    width: 100%;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 0.375rem 0.5rem;
    font-size: 14px;
    color: rgb(17 24 39);
    background: #ffffff;
    border: 1px solid #ffc107;
    outline: none;
}

.new-message .send-button {
    border: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background: #c3001f;
    width: fit-content;
    padding: 0.5rem 2rem;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
}

@media (min-width: 640px) {
    .refund-description label {
        font-size: 14px;
    }

    .defect-images p {
        font-size: 14px;
    }

    .messages p {
        font-size: 14px;
    }

    .attachments p {
        font-size: 14px;
    }

    .refund-reason label {
        font-size: 14px;
    }

    .refund-reason .select-container select {
        font-size: 14px;
    }

    .refund-resolution label {
        font-size: 14px;
    }

    .refund-resolution .select-container select {
        font-size: 14px;
    }

    .refund-item .item-condition label {
        font-size: 14px;
    }

    .refund-item .item-condition .select-container select {
        font-size: 14px;
    }

    .return-quantity label {
        font-size: 14px;
    }

    .return-quantity .input-container input {
        width: 5rem;
        font-size: 14px;
    }

    .return-quantity .input-container span {
        font-size: 14px;
    }

    .bank-account-no label,
    .bank-name label,
    .account-name label {
        font-size: 14px;
    }

    .bank-account-no .input-container input,
    .bank-name .input-container input,
    .account-name .input-container input {
        font-size: 14px;
    }

    .refund-description textarea {
        font-size: 14px;
    }

    .screenshot {
        height: 8rem;
        width: 8rem;
    }

    .add-screenshot {
        height: 8rem;
        width: 8rem;
    }

    .add-screenshot svg {
        width: 2.5rem;
        height: 2.5rem;
    }

    .add-screenshot span {
        font-weight: 500;
        font-size: 14px;
    }
}

@media (min-width: 768px) {
    .screenshot {
        height: 10rem;
        width: 10rem;
    }

    .add-screenshot {
        height: 10rem;
        width: 10rem;
    }

    .add-screenshot svg {
        width: 3rem;
        height: 3rem;
    }

    .add-screenshot span {
        font-weight: 500;
    }
}

@media (min-width: 1024px) {
    .refund-item .item-details {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
    }

    .refund-item .item-details .item-form {
        display: flex;
    }

    .refund-item .item-name {
        padding: 0 4rem;
    }

    .refund-item .item-condition {
        margin: 0 1rem;
    }
}
