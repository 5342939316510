.App {
  background-color: #ffffff;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  height: 100%;
}
html,
body {
  height: 100%;
}


.blackScreen {
  position: fixed;
  background-color: #000000;
  opacity: 0.6;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 10000;
  top: 0;
  left: 0;
}
.diy-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* main title */
h1 {
  font-size: 90px;
  font-weight: 900;
}
/* sub title */
h2 {
  font-size: 40px;
  font-weight: 800;
  margin-bottom: 1.2rem;
}
/* sec title */
h3 {
  font-size: 36px;
  font-weight: 700;
}
/* feature title */
h4 {
  font-size: 28px;
  font-weight: 700;
}
/* icon title */
h5 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0.9rem;
}
/* icon title */
h6 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0.9rem;
}
p {
  font-size: 18px;
  font-weight: 500;
}
.cursor { cursor: pointer;}
.bold {font-weight: 700;}
.white-txt { color: #fff;}

.text-normal {
  font-weight: 500;
}
.text-price-h2 {
  font-size: 54px;
}
.sub-price-h2 {
  font-size: 27px;
}
.text-price {
  line-height: 1.2rem;
}
.text-focus {
  font-weight: 900;
  font-size: 48px;
}
.text-less-bold {
  font-weight: 700;
}
.text-focus-sm {
  font-size: 18px;
  font-weight: 900;
}

.transition {
  transition: transform 2s ease-in;
}

.title-center {
  position: relative;
  display: table;
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
}
.title-center::after {
  content: "";
  position: absolute;
  background-color: #cc141c;
  width: 75%;
  height: 6px;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 20px);
}

.title-center-blue {
  position: relative;
  display: table;
  margin-bottom: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  z-index: 0;
}
.title-center-blue::after {
  content: "";
  position: absolute;
  background-color: #00a0e6;
  width: 75%;
  height: 15px;
  bottom: 0;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, 0px);
}

.title-left {
  position: relative;
  display: table;
  margin-bottom: 3rem;
}
.title-left::after {
  content: "";
  position: absolute;
  background-color: #cc141c;
  width: 25%;
  height: 6px;
  bottom: 0;
  left: 0;
  transform: translate(0%, 20px);
}

.border-red {
  border: 1px solid #cc141c !important;
}

/* modal */
.modal-content {
  border-radius: 0;
}

.underline_input {
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
}

a,
a:hover,
a:active {
  color: #000000;
  text-decoration: none;
}

.mt--2 {margin-top: -10px;}
.errormsg {
  color: #e80012;
  font-size: 16px;
}

.content_img img {
  display: block;
  background-color: #f5f5f5;
  margin-bottom: 20px;
}

.link_ {
  cursor: pointer;
}
.link_:hover {
  font-weight: 700 !important;
}

.link_slash {
  cursor: pointer;
  position: relative;
  margin-right: 20px;
}
.link_slash::after {
  content: "/";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(200%, -50%);
}

.link_blue {
  cursor: pointer;
  font-weight: 500;
  color: #00a0e6;
  text-decoration: none;
}
.link_blue:hover {text-decoration: underline; font-weight: 500!important; color: #00a0e6!important;}
.link_red {
  cursor: pointer;
  font-size: 18px;
  color: #e80012;
  font-weight: 700;
  text-decoration: none;
}
.link_red:hover {color: #e80012; text-decoration: underline;}

.form_ico_back {
  font-size: 70px;
  color: #cc3500;
  padding: 0px;
  position: absolute;
  left: 0;
  top: 0;
}

.w-35 {
  width: 35% !important;
}

.w-60 {
  width: 60% !important;
}

.max-300px {
  max-width: 300px !important;
}

/* cursor */
.cursor-nodrop {
  cursor: no-drop !important;
}

/* card */
.card-group > .card + .card {
  border-left: 1;
}

.bg-yellow-1 {
  background-color: #fcbc19;
}
.bg-yellow-2 {
  background-color: #fcd801;
}

.bg-red-1 {
  background-color: #cc141c;
}
.bg-red-2 {
  background-color: #e80012;
}

.bg-green-1 {
  background-color: #006837;
}
.bg-green-2 {
  background-color: #06c755;
}

.bg-orange {
  background-color: #ff5719;
}
.bg-blue {
  background-color: #00a0e6;
}

.bg-grey-1 {
  background-color: #333333;
}
.bg-grey-2 {
  background-color: #666666;
}
.bg-grey-3 {
  background-color: #969696;
}
.bg-grey-4 {
  background-color: #e3e3e3;
}
.bg-grey-5 {
  background-color: #efefef;
}
.bg-grey-6 {
  background-color: #f7f7f7;
}

.bg-green-link {
  background-color: #06c755;
  border-radius: 9px !important;
}
.bg-green-link:hover,
.bg-green-link:active {
  background-color: #06bd52;
  border-radius: 9px !important;
}
.bg-blue-link {
  background-color: #1e77ed;
  border-radius: 9px !important;
}
.bg-blue-link:hover,
.bg-blue-link:active {
  background-color: #0e6ae2;
  border-radius: 9px !important;
}
.bg-white-link {
  background-color: #ffffff;
  color: #000000 !important;
  border-radius: 9px !important;
}
.bg-white-link:hover,
.bg-white-link:active {
  background-color: #f5f5f5;
  color: #000000 !important;
  border-radius: 9px !important;
}
.bg-grey-link {
  background-color: #efefef;
  color: #000000 !important;
  border: none;
  border-radius: 9px !important;
}
.bg-grey-link:hover,
.bg-grey-link:active {
  background-color: #eeeeee;
  color: #000000 !important;
  border-radius: 9px !important;
}

.text-red-1 {
  color: #cc141c;
}
.text-red-2 {
  color: #e80012;
}

.text-orange-1 {
  color: #ff5719;
}

.text-green-1 {
  color: #006837;
}

.text-grey-1 {
  color: #666666;
}
.text-grey-footer {
  color: #969696;
}

.txt-thin {
  font-weight: 500;
}
.txt-bold {
  font-weight: bold;
}
.txt-super-bold {
  font-weight: 900;
}
.txt-stroke {
  text-decoration: line-through;
}

.highlight-title {
  font-size: 5rem;
}

sub {
  bottom: 0;
  font-size: 14px;
  font-weight: 900;
}

.img-10 {
  max-height: 10px;
}
.img-15 {
  max-height: 15px;
}
.img-20 {
  max-height: 20px;
}
.img-25 {
  max-height: 25px;
}
.img-30 {
  max-height: 30px;
}
.img-35 {
  max-height: 35px;
}
.img-40 {
  max-height: 40px;
}
.img-50 {
  max-height: 50px;
}
.img-60 {
  max-height: 60px;
}
.img-65 {
  max-height: 65px;
}
.img-70 {
  max-height: 70px;
}
.img-200 {
  max-height: 200px;
}
.img-80 {
  max-height: 80px;
}
.img-90 {
  max-height: 90px;
}
.img-100 {
  max-height: 100px;
}
.img-110 {
  max-height: 110px;
}
.img-120 {
  max-height: 120px;
}
.img-130 {
  max-height: 130px;
}

.txt-10 {
  font-size: 10px;
}
.txt-15 {
  font-size: 15px;
}
.txt-20 {
  font-size: 16px;
  margin-bottom: 0.6rem;
}
.txt-25 {
  font-size: 25px;
}
.txt-30 {
  font-size: 28px;
}
.txt-35 {
  font-size: 35px;
}
.txt-40 {
  font-size: 40px;
}
.txt-50 {
  font-size: 50px;
}
.txt-60 {
  font-size: 60px;
}
.txt-65 {
  font-size: 65px;
}
.txt-70 {
  font-size: 70px;
}

.input-20 {
  width: 20px;
  height: 20px;
  text-align: center;
}
.input-30 {
  width: 30px;
  height: 30px;
  text-align: center;
}
.input-50 {
  width: 50px;
  height: 50px;
  text-align: center;
}

.box-40 {
  width: 40px;
  height: 40px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.border-rounded {
  border-radius: 30px;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.input-relative {
  position: relative;
}
.input-absoulte {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-10px, -50%);
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f5f5f5;
  opacity: 1;
}
.my-6 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}
.my-7 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.max-100 {
  max-width: 100px;
}
.max-200 {
  max-width: 200px;
}

.wid-100 {
  width: 100%;
  max-width: 320px;
}
.wid-80 {
  width: 80%;
}
/* for the cart */
.wid-50 {
  width: 100%;
  max-width: 50%;
}
/* for the cart */
.wid-25 {
  width: 100%;
  max-width: 25%;
}
.wid-20 {
  width: 20%;
}

/* form-control */
.form-control {
  padding: 0.75rem 0.75rem;
  border-radius: 0;
}

.input_search {
  position: relative;
  overflow: hidden;
}

.border-none {
  border-bottom: 0px;
  border: transparent;
}

.box_search {
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* logout */
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  border-radius: 10px;
}
.dropdown-menu.show {
  padding: 0;
}

.btn_search {
  font-size: 30px;
  color: #ffffff;
}

.btn-main {
  position: relative;
  background-color: #fcd800;
  border-radius: 0px;
  padding: 0.75rem 3rem;
  font-size: 22px;
  font-weight: 700;
  overflow: hidden;
}
.btn-main::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  background-color: #e80012;
  border-radius: 0px;
  width: 50px;
  height: 50px;
  z-index: 10;
  transform: rotate(45deg) translate(10px, -45px);
}
.btn-main:hover,
.btn-main:focus,
.btn-main:active {
  background-color: #fcbc19;
  outline: 0px;
  box-shadow: none;
  border-color: transparent;
  color: #000000;
}

.btn-card-main {
  position: relative;
  background-color: #fcd800;
  border-radius: 0px;
  font-size: 18px;
  color: #000000;
  font-weight: 700;
  padding: 0.5rem 1.5rem;
  overflow: hidden;
  outline: none;
  border: none;
}
.btn-card-main::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  background-color: #e80012;
  border-radius: 0px;
  width: 50px;
  height: 50px;
  z-index: 10;
  transform: rotate(45deg) translate(10px, -50px);
}
.btn-card-main:hover,
.btn-card-main:focus,
.btn-card-main:active {
  background-color: #fcbc19;
  outline: 0px;
  box-shadow: none;
  font-weight: 700;
  border: none;
  color: #000000;
}

.btn-card-sec {
  position: relative;
  background-color: #e80012;
  border-radius: 0px;
  font-size: 18px;
  color: #ffffff;
  font-weight: 700;
  overflow: hidden;
  padding: 0.5rem 1.5rem;
  outline: none;
  border: none;
}
.btn-card-sec::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #fcd800;
  border-radius: 0px;
  width: 50px;
  height: 50px;
  z-index: 10;
  transform: rotate(135deg) translate(10px, -50px);
}
.btn-card-sec:hover,
.btn-card-sec:focus,
.btn-card-sec:active {
  background-color: #cc141c;
  outline: 0px;
  color: #ffffff;
  box-shadow: none;
  border: none;
}

.btn-card-thi {
  position: relative;
  background-color: #fcd800;
  border-radius: 0px;
  font-size: 18px;
  font-weight: 700;
  overflow: hidden;
  padding: 0.5rem 1.5rem;
  outline: none;
  border: none;
}
.btn-card-thi:hover,
.btn-card-thi:focus,
.btn-card-thi:active {
  background-color: #fcd800;
  outline: 0px;
  box-shadow: none;
  border: none;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  background-color: #777777 !important;
  color: #ffffff !important;
  pointer-events: auto !important;
  cursor: no-drop !important;
}

.border-divide {
  border-color: transparent #666666 transparent transparent;
  border-style: solid;
  border-width: 1px;
}

.border-cart-divide {
  border-color: #c9c9c9 transparent #c9c9c9 transparent;
  border-style: solid;
  border-width: 1px;
}

.border-top-1 {
  border-top: 1px solid #c9c9c9;
}

.border-bottom-1 {
  border-bottom: 1px solid #c9c9c9;
}

/* ratibng */
.star-dark {
  color: #c9c9c9;
}
.star-bright {
  color: #fcbc19;
}
.star-orange {
  color: #ff5719;
}

hr {
  margin: 0.5rem 0;
}

.col-3-cus {
  width: 7%;
}
.col-4-cus {
  width: 38%;
}
.col-6-cus {
  width: 14%;
}

.w-85 {
  width: 85% !important;
}

.fw-extraBold {
  font-weight: 900;
}

.right-0 {
  right: 0px;
}
.top-0 {
  top: 0px;
}

.text-bold {
  font-weight: 900 !important;
}

/* container */
@media (min-width: 1400px) {
  .container-xxl {
    max-width: 1700px;
  }
}

@media (max-width: 1198px) {
  .col-3-cus {
    width: 8%;
  }
  .col-6-cus {
    width: 10%;
  }
}

@media (max-width: 768px) {
  .border-divide {
    border-color: transparent transparent transparent transparent;
  }

  .box-40 {
    width: 30px;
    height: 30px;
  }

  .max-300px {
    max-width: 100% !important;
  }
}

@media (max-width: 992px) {
  /* main title */
  h1 {
    font-size: 36px;
    font-weight: 900;
  }
  /* sub title */
  h2 {
    font-size: 28px;
    font-weight: 800;
  }
  /* sec title */
  h3 {
    font-size: 24px;
    font-weight: 700;
  }
  /* feature title */
  h4 {
    font-size: 20px;
    font-weight: 700;
  }
  /* icon title */
  h5 {
    font-size: 18px;
    font-weight: 700;
  }
  /* icon title */
  h6 {
    font-size: 12px;
  }
  p {
    font-size: 14px;
    font-weight: 500;
  }

  .img-60 {
    max-height: 30px;
  }
  .img-65 {
    max-height: 40px;
  }
  .img-70 {
    max-height: 35px;
  }

  .text-price-h2 {
    font-size: 38px;
  }
  .sub-price-h2 {
    font-size: 16px;
  }
  .max-100 {
    max-width: inherit;
  }
  .max-200 {
    max-width: inherit;
  }

  sub {
    font-size: 10px;
  }

  .txt-30 {
    font-size: 24px;
  }
  /* menuico */
  .txt-50 {
    font-size: 40px;
  }
  .txt-60 {
    font-size: 30px;
  }
  .txt-65 {
    font-size: 32px;
  }
  .txt-70 {
    font-size: 35px;
  }

  .text-focus {
    font-size: 36px;
  }

  /* for the cart */
  .wid-75 {
    max-width: 100%;
  }
  .wid-50 {
    max-width: 100%;
  }
  /* for the cart */
  .wid-25 {
    max-width: 100%;
  }

  .col-3-cus {
    width: 25%;
  }
  .col-4-cus {
    width: 100%;
  }
  .col-6-cus {
    width: 25%;
  }

  .w-85 {
    width: 100% !important;
  }

  /* cusnav ico */
  .img-50 {
    max-height: 40px;
  }

  .btn-main {
    font-size: 14px;
  }
  .btn-card-main {
    font-size: 14px;
    padding: 0.5rem 1.5rem;
  }

  .btn-card-sec {
    font-size: 14px;
    padding: 0.5rem 1.5rem;
  }

  .btn-card-thi {
    font-size: 14px;
    padding: 0.5rem 1.5rem;
  }

  .title-center-blue::after {
    content: "";
    position: absolute;
    background-color: #00a0e6;
    width: 75%;
    height: 8px;
    bottom: 0;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, 0px);
  }
}

.space-y-3 > * + * {
  margin-top: 0.75rem;
}
