/* tab */
.App-footer {
    position: relative;
    width: 100%; background-color: #eeeeee;
}

.footer_tabs {
    background-color: #eeeeee; max-width: 1600px;
    margin: 0 auto; padding-top: 20px; border-bottom: none!important; padding-left: 20px; padding-right: 20px;
}



.footer_tabs .nav-item {
    max-width: 200px!important
}
.footer_tabs .nav-link { transition: none;}
.footer_tabs .nav-link:hover, .footer_tabs .nav-link:focus,.footer_tabs .nav-link:active,.footer_tabs .nav-link:focus-within {
    outline: none!important;
    border-radius: 0px!important;
    --bs-nav-tabs-border-width: 0px!important;
}

.footer_tabs .nav-link.active  {
    
    /* border-top-color: transparent!important;
    border-left-color: transparent!important;
    border-right-color: transparent!important; */
    border-radius: 0px!important;
    height: 100%; border: 1px solid #c9c9c9;
    border-bottom: none!important;
}




.footer_tabs .nav-link {
    --bs-nav-tabs-border-width: 0px!important;
    font-size: 18px!important;
    font-weight: 700!important;
    color: #666666!important;
    text-align: center!important;
    margin: auto;
    border-radius: 0px!important;
    height: 100%;
}

.footer_tabs .nav-link:hover,.footer_tabs .nav-link:active,.footer_tabs .nav-link:focus ,.footer_tabs .nav-link.active{
    color: #000000!important;
    font-weight: 700!important;
    border-radius: 0px!important;
    height: 100%;
}

.App-footer .tab-content { border-top: 1px solid #c9c9c9;  margin-top: -1px;}
.App-footer .tab-content > div > div {max-width: 1600px;  margin: 0 auto;}

.social_wrap{
    position: absolute;
    bottom: 50%;
    right: 0;
    transform: translateY(70%);
}

@media (max-width: 992px) {
  
    .footer_tabs .nav-item {
        max-width: auto!important
    }

    .social_wrap{
        position: relative;
        transform: translateY(0%);
    }
}

.verified-logos > * + * {
    margin-left: 1.5rem;
}