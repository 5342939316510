/* cart */
.hiCheveronLeft{
    font-size: 40px;
    color: #ffffff;
}
.cart_Col{
    padding: .5rem .5rem;
}
.cart_Col1{
    width: 100%;
    max-width: 5%;
}
.cart_Col2{
    width: 100%;
    max-width: 25%;
    text-align: center;
}
.cart_Col3{
    width: 100%; 
}


.cart_page .img-thumbnail{
    max-height: 200px;
    object-fit: cover;
    border: none;
}


@media (max-width: 768px) {
    .hiCheveronLeft {
        font-size: 20px;
    }
    .cart_page .input-50 {width: 30px; height: 30px; }
  }
