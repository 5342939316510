/* tab */
.nav-tabs{
    border-bottom: 1px solid #eeeeee!important;
    border-radius: none!important; --bs-nav-tabs-border-width: 0px!important;
}

.nav-tabs:hover, .nav-tabs:active, .nav-tabs:visited, .nav-tabs:focus-within {   
    --bs-nav-tabs-border-width: 0px!important;
}

.nav-item {
    max-width: 240px!important;
}

.nav-tabs .nav-link:hover, .nav-link:focus, .nav-link:active, .nav-link:focus-within {
    outline: none!important;
}

.nav-link.active  {
    border-bottom: 6px solid red!important;
    /* border-top-color: transparent!important;
    border-left-color: transparent!important;
    border-right-color: transparent!important; */
    border-color :transparent;
}




.nav-link {
    font-size: 30px!important;
    font-weight: 500!important;
    color: #666666!important;
    text-align: center!important;
    margin: auto;
}

.nav-link:hover, .nav-link:active, .nav-link:focus ,.nav-link.active{
    color: #000000!important;
    font-weight: 700!important;
}


@media (max-width: 992px) {
    .nav-item {
        max-width: auto!important;
    }
    .nav-link {
        font-size: 18px!important;
    }
    
  }