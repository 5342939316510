.ProductDetail_Page {
  position: relative;
}

.ProductDetail_Page .cart_Col2 {
  max-width: 30%;
}

.no-scroll {
  height: 100vh;
  overflow-y: hidden;
}

.fullPage {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.fullPage.showing {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000 !important;
  opacity: 0.7;
  display: block;
  z-index: 50;
}

.img-thumbnail {
  max-height: 300px;
  object-fit: cover;
  border: none;
}

.ProductDetail_Panel {
  position: fixed;
  width: 100%;
  height: auto;
  background-color: #f7f7f7;
  bottom: 0;
  left: 0;
  z-index: 100;
  opacity: 0.3;
  transition: 0.5s ease-in-out;
  display: none;
}

.ProductDetail_Panel.showing {
  opacity: 1;
  display: block;

  max-width: 880px;
  height: 400px;
  bottom: 0;
  left: 50%;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%); 
}

.ProductDetail_Panel_Box {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333333;
  font-size: 28px;
  font-weight: 700;
  color: #ffffff;
}

.Cartlist_Panel {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  max-width: 600px;
  background-color: #f7f7f7;
  top: 0;
  right: 0;
  z-index: 1000;
  display: none;
  opacity: 0.3;
  transition: 0.5s ease-in-out;
}

.Cartlist_Panel::-webkit-scrollbar {
  display: none;
}

.Cartlist_Panel.showing {
  opacity: 1;
  display: block;
}

.Cartlist_Panel_Box {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333333;
  font-size: 28px;
  font-weight: 700;
  color: #ffffff;
}
.Cartlist_Panel_Box:hover { background-color: #cc3500;}

.Cartlist_Panel_Box svg {  width: 50px; height: 50px; padding: 10px;  display: block;}

.product_carousel {
  position: relative;
  width: 100%;
  min-height: 680px;
  overflow: hidden;
}

.soldout-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.soldout {
  position: absolute;
  min-width: 150px;
  min-height: 150px;
  border-radius: 50%;
  color: white;
  font-size: 175%;
  background: rgba(0, 0, 0, 0.596);
  padding: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.carousel-root {
  position: absolute;
  top: 0;
  right: 0;
  padding: 2rem 2rem;
  transform: rotate(90deg) translate(-4rem, 4rem);
  max-width: 650px;
  /* display: flex;
    flex-direction: row-reverse; */
}

ul.thumbs {
  padding-left: 0rem;
  /* display: flex;
    flex-direction: column; */
}

ul.thumbs li img {
  transform: rotate(-90deg);
}

.thumbs-wrapper {
  position: relative;
  margin: 10px auto !important;
}

.carousel .control-arrow {
  background-color: #000000;
  border-radius: 30px;
  transform: translateY(-50%);
  opacity: 1;
  margin-top: -5px;
}
.carousel .control-prev.control-arrow {
  left: 1%;
}
.carousel .control-next.control-arrow {
  right: 1%;
}

.carousel .control-prev.control-arrow:before {
  margin: auto;
  display: inline-block;
  border: none;
  content: "<";
  padding: 0.2rem 0.4rem;
  color: #ffffff;
}

.carousel .control-next.control-arrow:before {
  margin: auto;
  display: inline-block;
  border: none;
  content: ">";
  padding: 0.2rem 0.4rem;
  color: #ffffff;
}

.carousel-slider {
  border: 1px solid #eee;
  /* max-width: 75%; */
}

.carousel.carousel-slider .control-arrow {
  display: none;
}

.rotate_img {
  transform: rotate(-90deg);
}

.carousel-status {
  display: none;
}

.carousel .control-dots .dot {
  background-color: #555;
  box-shadow: none;
  opacity: 0.3;
  display: none;
}

.product_infoTAG p {
  font-size: 16px;
}

.variance_ul {
  list-style: none;
  padding-left: 0rem;
}

.variance_box {
  border: 1px solid #eeeeee;
  padding: 15px 20px;
  border-radius: 10px;
  margin: 0px 10px auto;
  margin-bottom: 10px;
  display: inline-block;
}

/* .variance_box:hover, .variance_box:focus, .variance_box:active{
    border: 2px solid #FF5719;
} */

.border-focus {
  border: 2px solid #ff5719;
}

.notice_wrap {
  position: relative;
  overflow: hidden;
}
.notice_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notice_text {
  width: 82%;
  transform: translateX(60px);
}
.border.table {
  border-color: #555 !important;
}
.select_list {
  background-color: transparent !important;
  border: none !important;
  margin: 0 !important;
  width: 100% !important;
  font-family: inherit !important;
  cursor: inherit !important;
  line-height: inherit !important;
  outline: none !important;
  text-align: center;
  overflow: auto !important;
}

.review_sec {
  position: relative;
}

.review_sec h4 {
  position: relative;
  z-index: 1;
}

.review_sec .border-bottom-1 {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(-30px);
  z-index: 0;
  margin-left: 30px;
  width: calc(100% - 30px);
}

.breadcrumb-wrap ul { list-style: none; margin: 0; padding:0; overflow: hidden; display: flex; flex-direction: row; flex-wrap: nowrap; }
.breadcrumb-wrap ul li {width: auto; float: left; position: relative; text-align: center;}
.breadcrumb-wrap ul li a { float: left; width: 100%;  text-decoration: none; background-color: #EFEFEF; padding: 10px; padding-left: 50px; white-space: nowrap;}
.breadcrumb-wrap ul li:first-child a { padding:2px; padding-left: 10px; background: #333; margin-right: -8px;}
.breadcrumb-wrap ul li:first-child a:after { border-left: 30px solid #333;}
.breadcrumb-wrap ul li.current { overflow: hidden;}
.breadcrumb-wrap ul li.current a { background-color: transparent; font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;}
.breadcrumb-wrap ul li.current a:before,
.breadcrumb-wrap ul li.current a:after { display: none;}
.breadcrumb-wrap ul li a:before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid white;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  margin-left: 6px;
  left: 100%;
  z-index: 1;
} 
.breadcrumb-wrap ul li a:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid #EFEFEF;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
  transition: 100ms ease-out all;
}


@media (max-width: 1600px) {
  .product_carousel {
    position: relative;
    min-height: 100%;
    overflow: hidden;
  }
  .carousel-root {
    position: relative;
    max-width: 700px;
    transform: rotate(0deg);
    max-height: 100%;
  }
  ul.thumbs li img {
    transform: rotate(0deg);
  }
  .rotate_img {
    transform: rotate(0deg);
  }
  .carousel-slider {
    border: 1px solid #eee;
  }
}

@media (max-width: 1400px) {
  .product_infoTAG p {
    font-size: 14px;
  }
  .product_infoTAG svg {
    font-size: 14px;
  }
  .Cartlist_Panel {
    max-width: 450px;
  }
}
@media (max-width: 768px) {
  .ProductDetail_Page {
    position: relative;
  }
  .ProductDetail_Page .cart_Col2 {
    max-width: 35%;
  }

  .img-thumbnail {
    max-height: 150px;
    object-fit: cover;
  }

  .ProductDetail_Panel {
    position: fixed;
    bottom: 50%;

    height: fit-content;
  }

  .ProductDetail_Panel.showing {    height: auto;}

  .Cartlist_Panel {
    max-width: 350px;
  }
  .breadcrumb-wrap {font-size: 12px;}
  .breadcrumb-wrap ul li a {padding-right: 0px;}
  .breadcrumb-wrap ul li.current a {padding-left:40px}
  .breadcrumb-wrap ul li:first-child a img { width: 34px;}
  .soldout {
    position: absolute;
    min-width: 100px;
    min-height: 100px;
    border-radius: 50%;
    color: white;
    font-size: 125%;
    background: rgba(0, 0, 0, 0.596);
    padding: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }
}
